<template>
  <div class="box-main-tab">

    <div class="box-main-tab-left">
      <div class="box-main-tab-left-tit">
        <div>{{ $t('transaction.ky') }} {{USDT}} USDT</div>
        <div class="box-main-tab-left-img" @click="toRecharge">
          <div>{{ $t('transaction.cb') }}</div>
          <img src="@/assets/market/cb.png" alt="">
        </div>
      </div>
      <div class="display-flex align-items-center justify-content-space-between margin-bottom-2px">
        <div class="fs-12">{{ $t('transaction.xj') }}</div>
        <div class="fs-12">USDT</div>
      </div>
      <div class="fs-14">{{currentPrice}}</div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center justify-content-space-between margin-bottom-8px">
          <div class="fs-12">{{ $t('transaction.mrl') }}</div>
          <div class="fs-12">{{ $t('transaction.zs') }}{{mix_money}}USDT</div>
        </div>
        <div class="display-flex align-items-center justify-content-space-between">
          <input type="number" class="c-f-w-9" v-model="riseDate.money">
          <div class="fs-12">USDT</div>
        </div>
      </div>
      <div class="box-main-ul">
        <div v-for="(item,i) in riseList" :key="i" @click="selectType(1,item.id)"
        :class="[ulActive === item.id ? 'box-main-ul-active' : '']">{{item.future_time}}s {{item.profit}}%</div>
      </div>
      <div class="buy" @click="submit(1)">{{ $t('transaction.mz') }}</div>
    </div>



    <div class="box-main-tab-left">
      <div class="box-main-tab-left-tit">
        <div>{{ $t('transaction.ky') }} {{USDT}} USDT</div>
        <div class="box-main-tab-left-img" @click="toRecharge">
          <div>{{ $t('transaction.cb') }}</div>
          <img src="@/assets/market/cb.png" alt="">
        </div>
      </div>
      <div class="display-flex align-items-center justify-content-space-between margin-bottom-2px">
        <div class="fs-12">{{ $t('transaction.xj') }}</div>
        <div class="fs-12">USDT</div>
      </div>
      <div class="fs-14">{{currentPrice}}</div>
      <div class="box-main-kuang">
        <div class="display-flex align-items-center justify-content-space-between margin-bottom-8px">
          <div class="fs-12">{{ $t('transaction.mrl') }}</div>
          <div class="fs-12">{{ $t('transaction.zs') }}{{mix_money}}USDT</div>
        </div>
        <div class="display-flex align-items-center justify-content-space-between">
          <input type="number" class="c-f-w-9" v-model="fallDate.money">
          <div class="fs-12">USDT</div>
        </div>
      </div>
      <div class="box-main-ul">
       <div v-for="(item,i) in riseList" :key="i" @click="selectType(2,item.id)"
       :class="[ulActive2 === item.id ? 'box-main-ul-active2' : '']">{{item.future_time}}s {{item.profit}}%</div>
      </div>
      <div class="buy" @click="submit(2)" style="background:#FF6B65;">{{ $t('transaction.md') }}</div>
    </div>
  </div>
</template>

<script>
  import {getCoinItem} from '@/api/money.js';
  import {getFutureList,addFuture} from "@/api/future.js"
  export default {
      data() {
        return{
          isAJAX:false,
          ulActive:1,
          ulActive2:1,
          riseList:[],
          currentPrice:0,
          USDT:0,
          conin_id:3,
          mix_money:0,
          riseDate:{
            money:0,
          },
          fallDate:{
            money:0,
          },
        }
      },
      mounted() {
        this.getFutureList()
      },
      methods:{
        getFutureList(){
          getFutureList().then((res)=>{
            if(res.code === 1){
              this.riseList = res.data
              this.mix_money = res.data[0].mix_money
            }
          })
        },

        async getList(coinPrice,conin_id){
          this.currentPrice = coinPrice
          this.conin_id = conin_id
          await getCoinItem({coinId:2}).then((res)=>{
            if(res.code === 1){
              this.USDT = res.data.num * 1
            }
          })
        },
        toRecharge(){
          this.$router.push('./recharge')
        },
        selectType(type,id){
          if(type === 1){
            this.ulActive = id
          }else{
            this.ulActive2 = id
          }
        },
        setNum(num){
          return num.replace(/\b(0+)/gi,'')
        },
        lastFun(type,money,futureId){
          let fromData = {
            type:type,
            money: money,
            futureId: futureId,
            coin_id:this.conin_id,
          }
          addFuture(fromData).then((res)=>{
            if(res.code === 1){
              this.isAJAX = false
              this.ulActive = 1,
              this.ulActive2 = 1,
              this.riseDate = {
                money:0,
              },
              this.fallDate = {
                money:0,
              },
              this.$message({
                message: this.$t('common_msg.success'),
                type: 'success'
              });
              this.$emit('isGetNewList',true);
            }else{
              this.isAJAX = false
            }
          })
        },
        submit(type){
          if(type === 1){
            // 涨
            if(this.riseDate.money <= 0){
               return this.$message.error(this.$t('trade_spot.msgBuyNum'));
            }
            if(this.riseDate.money>this.USDT){
               return this.$message.error(this.$t('trade_spot.msgUstdNotEnough'));
            }
            if(this.riseDate.money<this.mix_money){
              return this.$message.error(this.$t('transaction.zs')+this.mix_money+'USDT');
            }
            this.riseDate.money = this.setNum(this.riseDate.money)
            if(this.riseDate.money[0] === '.'){
              this.riseDate.money = '0' + this.riseDate.money
            }
            if(this.isAJAX){
              return
            }
            this.isAJAX = true
            this.lastFun(1,this.riseDate.money,this.ulActive)
          }else{
            // 跌
            if(this.fallDate.money <= 0){
               return this.$message.error(this.$t('trade_spot.msgBuyNum'));
            }
            if(this.fallDate.money>this.USDT){
               return this.$message.error(this.$t('trade_spot.msgUstdNotEnough'));
            }
            if(this.fallDate.money<this.mix_money){
              return this.$message.error(this.$t('transaction.zs')+this.mix_money+'USDT');
            }
            this.fallDate.money = this.setNum(this.fallDate.money)
            if(this.fallDate.money[0] === '.'){
              this.fallDate.money = '0' + this.fallDate.money
            }
            if(this.isAJAX){
              return
            }
            this.isAJAX = true;
            this.lastFun(2,this.fallDate.money,this.ulActive2)
          }
        },
      },
  }
</script>

<style scoped lang="scss">
  .box-main-tab {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 16px 42px;
    justify-content: space-between;

    .display-flex{
      display: flex;
    }
    .align-items-center{
      align-items: center
    }
    .justify-content-space-between{
      justify-content: space-between
    }
    .margin-bottom-2px{
      margin-bottom: 2px
    }
    .margin-bottom-8px{
      margin-bottom: 8px
    }
    .fs-12{
      font-size: 12px;color: rgba(255, 255, 255, 0.6);
    }
    .fs-14{
      font-size: 14px;color: #fff;margin-bottom: 20px;
    }
    .c-f-w-9{
      color: #FFF;width: 90%;
    }
    ::v-deep input::-webkit-outer-spin-button{
      -webkit-appearance: none !important;
    }
    ::v-deep input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    ::v-deep input[type='number'] {
      -moz-appearance: textfield;
    }
    .box-main-tab-left {
      width: 48%;
      .box-main-tab-left-tit {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }

      .box-main-tab-left-img {
        color: #FFC300;
        display: flex;
        align-items: center;
        div {
          margin-right: 4px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .box-main-kuang {
      width: 100%;
      border-radius: 2px;
      border: 1px solid #29343D;
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      margin-bottom: 26px;
      padding: 10px 12px;
      box-sizing: border-box;
    }
    .box-main-ul {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      margin-bottom: 28px;
      flex-flow: row wrap;
      div {
        background: #29343D;
        width: 25%;
        text-align: center;
        font-size: 12px;
        padding: 8px 0;
        color: rgba(255, 255, 255, 0.6);
      }
      .box-main-ul-active {
        background: #43CF7C;
        color: #212121 !important;
      }

      .box-main-ul-active2 {
        background: #FF6B65;
        color: #212121 !important;
      }
    }
    .buy {
      width: 100%;
      height: 36px;
      border-radius: 2px;
      background: #43CF7C;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(33, 33, 33, 1);
    }
  }
</style>
