<template>
  <div>
    <div class="new" style="border-bottom:none;">{{ $t('transaction.zc') }}(USDT)</div>
    <div class="padding10px">
      <div class="box-main-kuang2">
        <div class="padding10px">
          <div class="box-title">
            <div class="fs-12">{{ $t('transaction.ky') }}</div>
            <div class="fs-12">USDT</div>
          </div>
        </div>
        <div class="box">
          <div class="c-f-w-9">{{USDT}}</div>
        </div>
      </div>
    </div>
    <div class="padding10px">
      <div class="box-main-kuang2">
        <div class="padding10px">
          <div class="box-title">
            <div class="fs-12">{{ $t('transaction.dj') }}</div>
            <div class="fs-12">USDT</div>
          </div>
        </div>
        <div class="box">
          <div class="c-f-w-9">{{lock_num}}</div>
        </div>
      </div>
    </div>
   <!-- <div class="padding10px">
      <div class="box-main-kuang2">
        <div class="padding10px">
          <div class="box-title">
            <div class="fs-12">{{ $t('transaction.yk') }}</div>
            <div class="fs-12">USDT</div>
          </div>
        </div>
        <div class="box">
          <div class="c-f-w-9">0</div>
        </div>
      </div>
    </div> -->
    <div style="display:flex;">
      <div class="cb-btn" @click="recharge">{{ $t('transaction.cb') }}</div>
    </div>
  </div>
</template>

<script>
  import {getCoinItem} from '@/api/money.js';
  export default {
    data(){
      return{
          USDT:0,
          lock_num:0,
      }
    },
    mounted() {
      getCoinItem({coinId:2}).then((res)=>{
        if(res.code === 1){
          this.USDT = res.data.num * 1
          this.lock_num = res.data.lock_num * 1
        }
      })
    },
    methods: {
      recharge(){
        this.$router.push('./recharge')
      },
    }
  }
</script>

<style scoped lang="scss">
  .new {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    border-top: 1px solid #29343D;
    border-bottom: 1px solid #29343D;
    padding: 10px;
  }
  .padding10px{
    padding:0 10px
  }
  .box-title{
    display: flex;align-items: center;justify-content: space-between;margin-bottom:8px;
  }
  .fs-12{
    font-size: 12px;color: rgba(255, 255, 255, 0.6);
  }
  .box-main-kuang2 {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #29343D;
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    margin-bottom: 26px;
    box-sizing: border-box;
    padding-top: 4px;
    border-bottom: none;
    .box{
      display: flex;align-items: center;justify-content: space-between;background:#29343D;padding:4px 10px;
      .c-f-w-9{
        color: #FFF;width: 90%;
      }
    }
  }
  .cb-btn {
    width: auto;
    height: 32px;
    border-radius: 4px;
    background: #FFC300;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(33, 33, 33, 1);
    margin: 0 auto;
    padding:0 42px;
  }

</style>
